onBurgerMenuClick = () => {
    const nav = getNavContainer();

    if (nav) {
        nav.classList.toggle('is-open');
    }
}

getNavContainer = () => {
    return document.getElementById('header__nav_container');
}

getBurger = () => {
    return document.getElementById('burger');
}

closeMenu = () => {
    onBurgerMenuClick();
}